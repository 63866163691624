import AppLoader from '@/components/AppLoader';
import AppConfigContext from '@/context/AppConfigContext';
import { useGetApplicationConfigQuery } from '@/queries/application-config.queries';
import IApplicationConfig from '@/types/IApplicationConfig';
import React, { PropsWithChildren } from 'react';
interface IAppConfigProviderProps {
	config?: IApplicationConfig;
}

/**
 * Two implementations, one for testing will use the injected configuration
 */
export default function AppConfigProvider(props: PropsWithChildren<IAppConfigProviderProps>) {
	if (props.config) {
		return <TestAppConfigProvider config={props.config} {...props} />;
	}

	return <PrdAppConfigProvider {...props} />;
}

function PrdAppConfigProvider(props: React.PropsWithChildren<{}>) {
	const queryConfig = useGetApplicationConfigQuery();

	if (!queryConfig.data) {
		return <AppLoader />;
	}

	return <AppConfigContext.Provider value={queryConfig.data} {...props} />;
}

interface ITestAppConfigProviderProps {
	config: IApplicationConfig;
}

function TestAppConfigProvider(props: PropsWithChildren<ITestAppConfigProviderProps>) {
	return <AppConfigContext.Provider value={props.config} {...props} />;
}
