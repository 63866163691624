import client from '@/api_client/client';
import { EP_TENANT } from '@/constants/endpoints';
import appConfigService from '@/services/app-config/app-config.service';
import { IGetPublicTenantResponse, IRawTenantResponse, ITenant } from '@/types/apiClient/ITenant';

export function get() {
	return client
		.get<IRawTenantResponse>(EP_TENANT)
		.then((res) => res.data)
		.then((raw) => {
			return {
				...raw,
				webAppConfig: appConfigService.parseFromServerJSON(raw.webAppConfig),
			};
		});
}

export function update(value: Partial<ITenant>) {
	return client.patch<IRawTenantResponse>(EP_TENANT, {
		...value,
	});
}

function getPublicInfo() {
	return client.get<IGetPublicTenantResponse>('public/tenant').then((res) => res.data);
}

export default { getPublicInfo, get, update };
