/**
 * Query parameter used to represent the date from which the grid should be displayed
 */
export const TTF = "ttf";
/**
 * Query parameter used to represent the date until something should be displayed
 */
export const TTT = "ttt";
/**
 * Query parameter used to represent the number of units selected
 */
export const TTU = "ttu";
/**
 * Query parameter used to represent the selected timezone
 */
export const TTZ = "ttz";
/**
 * Query parameter used to represent the start of the selected slot start
 */
export const TTS = "tts";
/**
 * Query parameter used to represent the end of the selected slot end
 */
export const TTE = "tte";

/**
 * Query parameter used to represent the selected day on the BP2 theme
 */
export const TTD = "ttd";

/**
 * Query parameter to represent the color
 */
export const TTC = "ttc";

/**
 * Controls how the booking list is displayed.
 * Values:
 * 	- week: Week view
 *  - list: Agenda view (default)
 */
export const BOOKING_LIST_VIEW_MODE = "ttvm";

/**
 * Controls how the calendar is displayed.
 * - month
 * - planner
 * - week
 */
export const QP_CALENDAR_VIEW_MODE = "ttvm";

/**
 *
 */
export const QP_CALENDAR_VIEW_MODE_DAYS = "ttvmd";

/**
 * Used in the booking page details to display a modal to edit availability.
 */
export const QP_EDIT_RESOURCE_ID = "erid";

/**
 *
 */
export const TTLANG = "ttlg";
