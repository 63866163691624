import api_client from '@/repositories';
import tenantRepository from '@/repositories/tenant.repository';
import appConfigService from '@/services/app-config/app-config.service';
import { ICustomQueryArgsRepository } from '@/types/utils/ICustomQueryArgs';
import { useQuery } from '@tanstack/react-query';

export const QK_APP_CONFIG = 'application-config';

type IUseAppConfigArgs = ICustomQueryArgsRepository<typeof api_client.config.getConfig>;

export function useGetApplicationConfigQuery(args?: IUseAppConfigArgs) {
	return useQuery({
		queryKey: [QK_APP_CONFIG],
		queryFn: async () => {
			const { webAppConfig } = await tenantRepository.getPublicInfo();
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);
			const config = appConfigService.parseConfigurationFromQueryParams(webAppConfig, params);
			return config;
		},
		...args,
	});
}
