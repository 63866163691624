import IApplicationConfig from '@/types/IApplicationConfig';

export const BASE_APP_CONFIG: IApplicationConfig = {
	name: 'TimeTime.in',
	hasEmailAndPasswordLogin: true,
	hasGoogleLogin: true,
	hasMicrosoftLogin: true,
	hasGoogleCalendar: true,
	hasMicrosoftCalendar: true,
	hideSidebar: false,
	hideTopBar: false,
	BookingConfirmationPage: {
		hideBranding: false,
	},
	BookingFormPage: {
		hideBranding: false,
	},
	LoginPage: {
		imageUrl: '',
	},
	theme: {
		ttColor: 'primary',
	},
	eventTypes: {
		bookingMode: {
			defaultValue: {
				type: 'InstantBookingMode',
			},
			mode: 'W',
		},
		redirectAfterBooking: {
			defaultValue: '',
			mode: 'W',
		},
	},
};

export function parseConfigurationFromQueryParams(
	baseConfig: IApplicationConfig,
	params: URLSearchParams
): IApplicationConfig {
	const config = structuredClone(baseConfig);

	const ttHideToolbar = params.get('tt_hide_sidebar');

	if (ttHideToolbar === 'true') {
		config.hideSidebar = true;
	}

	const ttHideTopBar = params.get('tt_hide_top_bar');

	if (ttHideTopBar === 'true') {
		config.hideTopBar = true;
	}

	const name = params.get('tt_app_name');

	if (name) {
		config.name = name;
	}

	const themeColor = params.get('tt_theme_color');

	if (themeColor) {
		config.theme.ttColor = themeColor;
	}

	return config;
}

function parseFromServerJSON(serverConfig: IApplicationConfig): IApplicationConfig {
	const config = structuredClone(BASE_APP_CONFIG);

	if (serverConfig.name) {
		config.name = serverConfig.name;
	}

	if (serverConfig.hasEmailAndPasswordLogin !== undefined) {
		config.hasEmailAndPasswordLogin = serverConfig.hasEmailAndPasswordLogin;
	}

	if (serverConfig.hasGoogleLogin !== undefined) {
		config.hasGoogleLogin = serverConfig.hasGoogleLogin;
	}

	if (serverConfig.hasMicrosoftLogin !== undefined) {
		config.hasMicrosoftLogin = serverConfig.hasMicrosoftLogin;
	}

	if (serverConfig.hasGoogleCalendar !== undefined) {
		config.hasGoogleCalendar = serverConfig.hasGoogleCalendar;
	}

	if (serverConfig.hasMicrosoftCalendar !== undefined) {
		config.hasMicrosoftCalendar = serverConfig.hasMicrosoftCalendar;
	}

	if (serverConfig.hideSidebar !== undefined) {
		config.hideSidebar = serverConfig.hideSidebar;
	}

	if (serverConfig.hideTopBar !== undefined) {
		config.hideTopBar = serverConfig.hideTopBar;
	}

	if (serverConfig.BookingConfirmationPage?.hideBranding !== undefined) {
		config.BookingConfirmationPage = {
			...config.BookingConfirmationPage,
			hideBranding: serverConfig.BookingConfirmationPage.hideBranding,
		};
	}

	if (serverConfig.BookingFormPage?.hideBranding !== undefined) {
		config.BookingFormPage = { ...config.BookingFormPage, hideBranding: serverConfig.BookingFormPage.hideBranding };
	}

	if (serverConfig.LoginPage?.imageUrl) {
		config.LoginPage = { ...config.LoginPage, imageUrl: serverConfig.LoginPage.imageUrl };
	}

	if (serverConfig.theme?.ttColor) {
		config.theme.ttColor = serverConfig.theme.ttColor;
	}

	if (serverConfig.eventTypes?.bookingMode?.defaultValue) {
		config.eventTypes.bookingMode.defaultValue = serverConfig.eventTypes.bookingMode.defaultValue;
	}

	if (serverConfig.eventTypes?.bookingMode?.mode) {
		config.eventTypes.bookingMode.mode = serverConfig.eventTypes.bookingMode.mode;
	}

	if (serverConfig.eventTypes?.redirectAfterBooking?.defaultValue !== undefined) {
		config.eventTypes.redirectAfterBooking.defaultValue = serverConfig.eventTypes.redirectAfterBooking.defaultValue;
	}

	if (serverConfig.eventTypes?.redirectAfterBooking?.mode) {
		config.eventTypes.redirectAfterBooking.mode = serverConfig.eventTypes.redirectAfterBooking.mode;
	}

	return config;
}

export default {
	parseConfigurationFromQueryParams,
	parseFromServerJSON,
};
